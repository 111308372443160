@import 'normalize';

@import 'variebles';

@import 'fonts';
@import 'responce';
@import 'header';
@import 'home';
@import 'footer';

body {
    overflow-x: hidden;
}

.container {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    padding: 0 15px;
}

.section-title {
    font-family: $fontPTSansBold;
    font-size: 40px;
    line-height: 50px;
    color: #172134;
    text-align: center;
    margin-bottom: 75px;
    @include mediaw(480px) {
        font-size: 27px;    
        line-height: 35px;
        margin-bottom: 35px;
    }
    span {
        display: block;
        text-transform: uppercase;
    }
}

.price__row {
    display: flex;
    // justify-content: space-around;
    align-items: center;
    max-width: 482px;
    min-height: 95px;
    border-radius: 47px;
    margin: 20px auto 0;
    background-color: #f7f7f7;
    position: relative;
    padding: 0 0 0 115px;
    transform: translateX(-65px);
    // @include mediaw(749px) {
    //     padding: 15px;
    // }
    // @include mediaw(480px) {
    //     margin: 30px auto 0;
    //     padding: 7px 5px;
    //     min-height: initial;
    // }
    // @include mediaw(360px) {
    //     padding: 5px;
    //     border-radius: 25px;
    // }
    @include mediaw(630px) {
        max-width: 325px;
        padding: 0 0 0 25px;
        transform: translateX(-45px);
    }
    @include mediaw(576px) {
        transform: translateX(-35px);
    }
    @include mediaw(477px) {
        transform: translateX(-10px);
        min-height: 75px;
    }
    @include mediaw(374px) {
        max-width: 300px;
    }
    &-block {
        // max-width: 404px;
        // height: 100px;
        // border-radius: 50px;
        color: #606060;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        // @include mediaw(600px) {
        //     width: calc(50% - 5px);
        //     margin: 0 auto;
        // }
        // @include mediaw(480px) {
        //     height: 50px;
        // }
        .old, .new {
            font-family: $fontPTSansRegular;
            font-size: 20px;
            line-height: 24px;
            @include mediaw(477px) {
                font-size: 14px;
            }
            @include mediaw(360px) {
                font-size: 14px;
                line-height: 22px;
            }
            span {
                font-size: 30px;
                line-height: 40px;
                color: #181f2f;
                @include mediaw(576px) {
                    font-size: 25px;
                }
                @include mediaw(480px) {
                    font-size: 23px;
                }
                @include mediaw(374px) {
                    font-size: 16px;
                }
            }
        }
        // .old {
        //     padding: 0 20px 0 40px;
        //     @include mediaw(400px) {
        //         padding: 0 10px 0 30px;
        //     }
        //     @include mediaw(360px) {
        //         padding: 0 10px 0 20px;
        //     }
        //     span {
        //         display: inline-block;
        //         position: relative;
        //         //font-family: $fontFuturaLight;
        //         &::after {
        //             content: " ";
        //             display: block;
        //             height: 2px;
        //             width: 100%;
        //             position: absolute;
        //             top: 50%;
        //             transform: translateY(-50%);
        //             left: 0;
        //             background-color: #f75252;
        //         }
        //     }
        // }
        .new {
            // padding: 0 20px;
            @include mediaw(480px) {
                padding: 0 10px;
            }
            span {
                font-family: $fontPTSansBold;
            }
        }
    }
    .price__order {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 265px;
        height: 60px;
        border-radius: 31px;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);
        background-color: #ef8631;
        color: #fff;
        font-family: $fontPTSansBold;
        font-size: 24px;
        line-height: 40px;
        position: absolute;
        right: -130px;
        top: 50%;
        transform: translateY(-50%);
        @include mediaw(630px) {
            width: 215px;
            right: -80px;
        }
        @include mediaw(576px) {
            right: -70px;
        }
        @include mediaw(477px) {
            right: -15px;
            font-size: 18px;
            height: 45px;
            width: 175px;
        }
        @include mediaw(340px) {
            width: 155px;
        }
        // @include mediaw(600px) {
        //     width: calc(50% - 5px);
        //     margin-left: 10px;
        // }
        // @include mediaw(480px) {
        //     font-size: 17px;
        // }
        // @include mediaw(374px) {
        //     font-size: 16px;
        // }
    }
}