.footer {
    background-color: #172134;
    padding: 15px 0;
    color: #fff;
    text-align: center;
    font-family: $fontPTSansRegular;
    font-size: 16px;
    line-height: 22px;
    a {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    &__contacts {
        color: #fff;
        opacity: .5;
        font-family: Arial;
        margin: 10px 0;
        font-weight: bold;
        font-size: 12px;
        a {
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}